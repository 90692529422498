import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import axiosInstance from './api/axiosInstance';
import redIconUrl from './images/red.png';
import blueIconUrl from './images/blue.png';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
    lat: 43.818845305321226,
    lng: 18.36490550987273
};

const redIcon = {
    url: redIconUrl // Reference the imported custom icon
};

const blueIcon = {
    url: blueIconUrl // Reference the imported custom icon
};

const GoogleMapComponent = () => {
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        // Fetch markers from the database with authentication token
        axiosInstance.get('/drivers')
            .then(response => {
                console.log('Drivers fetched successfully:', response.data);
                setMarkers(response.data);
            })
            .catch(error => {
                console.error('Error fetching markers:', error);
            });
    }, []);

  return (
    <LoadScript googleMapsApiKey={mapsApiKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={16}
      >
              {markers.map((driver, index) => (
                  <MarkerF
                      key={index}
                      position={{ lat: driver.latitude, lng: driver.longitude }}
                      label={driver.car_number}
                      icon={driver.activity_status === 'active' ? redIcon : blueIcon}
                  />
              ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
